<template>
  <b-modal
    ref="modalUpdateNote"
    centered
    @show="show"
    @hidden="hide"
    body-class="p-4"
    v-model="isShowModal"
  >
    <template #modal-header>
      <div class="ft-black">Additional Notes</div>
    </template>
    <div>
      <InputTextArea
        :rows="4"
        textFloat="Additional Notes (If Any)"
        placeholder="Additional Notes"
        id="note"
        v-model="form.note"
        :isValidate="v.filterNote.note.$error"
        :v="v.filterNote.note"
      />
    </div>
    <template #modal-footer>
      <b-row>
        <b-col
          ><b-button @click="hide" class="btn-cancel"> Cancel </b-button></b-col
        >
        <b-col
          ><b-button class="btn-confirm" @click.prevent="saveNote">
            Save
          </b-button></b-col
        >
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    InputTextArea,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isShowModal: false,
    };
  },
  methods: {
    show() {
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    saveNote() {
      this.v.filterNote.$touch();
      if (this.v.filterNote.$error) {
        return;
      }
      this.$emit("updateNote", this.form);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--theme-secondary-color);
}
.ft-black {
  color: #000;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}
</style>
