<template>
  <div>
    <div class="title-tabs">General Information</div>
    <div class="bg-white px-3 py-3">
      <div class="w-50">
        <div class="position-relative">
          <InputText
            textFloat="Start Mission At"
            placeholder="0"
            type="number"
            name="name"
            v-model="form.start_mission_at"
            :v="v.start_mission_at"
            :isValidate="v.start_mission_at.$error"
            :disabled="userRedeem"
            @input="(val, e) => handleMaxGoal(e, val)"
          />
          <div
            class="input-description"
            :style="v.start_mission_at.$error ? 'top:55%' : 'top:70%'"
            style="right: 1.5%"
          >
            {{ textType }}
          </div>
        </div>
        <b-form-checkbox
          :value="1"
          :unchecked-value="0"
          v-model="form.is_complete_when_redeem"
          :disabled="userRedeem"
        >
          Complete When Redeem
        </b-form-checkbox>
        <b-form-checkbox
          :value="1"
          :unchecked-value="0"
          v-model="form.is_transaction_earn_multiple_step"
          :disabled="
            userRedeem ||
            form.mission_redeem_type == 1 ||
            form.mission_redeem_type == 3
          "
        >
          Single Transaction can earn multiple step (Type: Frequency only)
          <br />
          Example. Each step : 5000 Baht, Customer buy 12,000 will get 2 Steps
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting",
  props: {
    form: { required: true },
    v: { required: true },
    userRedeem: { required: true },
  },
  watch: {
    "form.mission_redeem_type"(val) {
      if (val == 1 || val == 3) this.form.is_transaction_earn_multiple_step = 0;
    },
    "form.mission_redeem_goal"(val) {
      if (this.form.start_mission_at > 0 && this.form.start_mission_at > val)
        this.form.start_mission_at = val;
    },
  },
  computed: {
    textType() {
      if (this.form.mission_redeem_type == 1) return "Bath";
      else if (this.form.mission_redeem_type == 2) return "Stamps";
      else return "Point";
    },
  },
  methods: {
    handleMaxGoal(e, val, index) {
      if (
        this.form.mission_redeem_type == 1 ||
        this.form.mission_redeem_type == 3
      ) {
        if (parseInt(val) >= this.form.mission_redeem_goal) {
          this.$nextTick(() => {
            this.form.start_mission_at = parseInt(
              this.form.mission_redeem_goal
            );
            e.target.value = parseInt(this.form.mission_redeem_goal);
            return e.preventDefault();
          });
        }
      }
    },
  },
};
</script>

<style></style>
