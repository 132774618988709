<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Mission DETAIL</h1>
    </div>

    <OtherLoading v-if="isLoading" />
    <b-tabs class="mt-3" v-model="tabIndex" v-else>
      <b-tab title="Mission Info">
        <Info
          :form="form"
          :v="$v.form"
          ref="info"
          :userRedeem="userRedeem"
          :missionType="missionType"
      /></b-tab>
      <b-tab title="Mission Detail">
        <Detail
          ref="missionDetail"
          :form="form"
          :mission_detail="form.mission_detail"
          :userRedeem="userRedeem"
        />
      </b-tab>
      <b-tab title="Settings">
        <Setting :form="form" :v="$v.form" :userRedeem="userRedeem" />
      </b-tab>
      <b-tab title="Report" v-if="$route.params.id != 0">
        <Report :form="form" />
      </b-tab>
      <b-tab title="Report Status" v-if="$route.params.id != 0">
        <ReportStatus :form="form" />
      </b-tab>
    </b-tabs>
    <FooterAction routePath="/mission" @submit="submit" />
  </div>
</template>

<script>
import { Info, Setting, Report, Detail, ReportStatus } from "./components";
import OtherLoading from "@/components/loading/OtherLoading";
import {
  required,
  requiredIf,
  minValue,
  minLength,
} from "vuelidate/lib/validators";

export default {
  components: {
    Info,
    Setting,
    Report,
    Detail,
    OtherLoading,
    ReportStatus,
  },
  data() {
    return {
      form: {
        name: "",
        icon: "",
        banner: "",
        valid_type: 1,
        valid_from: "",
        valid_to: "",
        invalid_after_redeem: 1,
        mission_redeem_condition: 1,
        mission_customer_segment_id: 3, // 1 personalize 2 segment 3 all
        customer_segment_value: "", // ถ้าเลือก segment ใส่ id segment ตรงนี้
        mission_redeem_type: 1, // 1 spending , 2 frequency
        // mission_redeem_bill: 0, // ถ้าเลือก frequency ใส่ condition ตรงนี้ ไม่แน่ใจว่าเป็นอะไรบ้างน่าจะรอ ui
        mission_redeem_goal: 0, // value
        select_branch: null, // all ส่ง null
        select_product: [], // all ส่ง null
        description: "",
        status: 1,
        start_mission_at: 0, // หน้า setting default ส่ง 1
        is_complete_when_redeem: 0, // หน้า setting default ส่ง 0
        mission_detail: [],
      },
      tabIndex: 0,
      isLoading: true,
      userRedeem: false,
      missionType: 0,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      icon: {
        required,
      },
      banner: {
        required,
      },
      valid_from: {
        required: requiredIf(function (item) {
          return item.valid_type == 2;
        }),
      },
      valid_to: {
        required: requiredIf(function (item) {
          return item.valid_type == 2;
        }),
      },
      mission_redeem_goal: {
        required: requiredIf(function (item) {
          return item.mission_redeem_type == 1 || item.mission_redeem_type == 3;
        }),
      },
      invalid_after_redeem: {
        required: requiredIf(function (item) {
          return item.valid_type == 1;
        }),
        minValue: minValue(1),
      },
      customer_segment_value: {
        required: requiredIf(function (item) {
          return item.mission_customer_segment_id == 2;
        }),
      },
      description: {
        required,
      },
      select_branch: {
        required,
      },
      start_mission_at: {
        required,
        minValue: minValue(0),
      },
    },
  },
  created() {
    if (this.$route.params.id != 0) this.getData();
    else this.isLoading = false;
  },

  methods: {
    async getData() {
      this.isLoading = true;
      const res = await this.axios(`/mission/get/${this.$route.params.id}`);
      this.userRedeem = res.data.detail.is_redeem != 0 ? true : false;
      this.missionType = res.data.detail.mission_customer_segment_id;
      res.data.detail.select_branch = res.data.detail.select_branch_detail
        ? res.data.detail.select_branch_detail.map((el) => el.id)
        : null;
      res.data.detail.select_product = res.data.detail.select_product_detail
        ? JSON.parse(
            JSON.stringify(
              res.data.detail.select_product_detail.map((el) => el.id)
            )
          )
        : null;

      res.data.detail.mission_detail = res.data.detail.mission_detail.sort(
        (a, b) => a.when_reach - b.when_reach
      );

      this.form = res.data.detail;
      this.isLoading = false;
    },
    async submit() {
      this.$v.$touch();
      let mission_detail = this.$refs.missionDetail.submit();

      if (this.$v.form.$error) return;
      if (!mission_detail) return (this.tabIndex = 1);
      let payload = JSON.parse(JSON.stringify(this.form));

      if (payload.mission_redeem_type == 1 || payload.mission_redeem_type == 3)
        payload.mission_detail = mission_detail.filter(
          (el) => el.mission_detail_type_id != 4
        );
      else payload.mission_detail = mission_detail;
      payload.valid_from =
        payload.valid_type == 1 ? this.$moment() : payload.valid_from;
      payload.valid_to =
        payload.valid_type == 1 ? this.$moment() : payload.valid_to;
      payload.select_product =
        this.$refs.info.isAllProduct == 0 ? payload.select_product : null;
      payload.customer_segment_value = payload.customer_segment_value
        ? payload.customer_segment_value
        : 0;
      this.$bus.$emit("showLoading");
      try {
        if (this.$route.params.id == 0) {
          const res = await this.axios.post("/mission/Create", payload);

          if (res.data.result == 1) {
            this.successAlert().then(() => this.$router.push("/mission"));
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          const res = await this.axios.put("/mission/update", payload);
          if (res.data.result == 1) {
            this.successAlert().then(() => this.$router.push("/mission"));
          } else {
            this.errorAlert(res.data.message);
          }
        }
        this.$bus.$emit("hideLoading");
      } catch (error) {
        this.errorAlert(error.message);
        this.$bus.$emit("hideLoading");
      }
    },
  },
};
</script>

<style></style>
